import { isNative } from '~/plugins/native/capacitor'

// Initialise over the air updates from our self hosted OTA system
export default async function ({ store, app, $log, redirect }, inject) {
  const otaUpdates = {}

  if (isNative) {
    const { CapacitorUpdater } = await import('@capgo/capacitor-updater')
    const { SplashScreen } = await import('@capacitor/splash-screen')

    let data = null

    $log.debug('OTA notifying ready')

    CapacitorUpdater.notifyAppReady()

    otaUpdates.sync = async isActive => {
      if (process.env.APP_ENVIRONMENT === 'local') {
        return false
      }

      const currentBundle = await CapacitorUpdater.current()

      console.log({ currentBundle })

      if (currentBundle.bundle?.version) {
        store.commit('device/setBundleVersion', currentBundle.bundle.version)
      }

      const isOnAuthPage = app.router.history?.current?.path?.includes('auth/')

      if (isActive) {
        store.commit('overTheAirUpdates/setStatusCode', 5)

        try {
          const latest = await CapacitorUpdater.getLatest()

          $log.debug('OTA latest', latest)

          if (latest.url) {
            store.commit('overTheAirUpdates/setStatusCode', 7)

            $log.debug('OTA downloading...')

            data = await CapacitorUpdater.download({
              url: latest.url,
              version: latest.version
            })

            $log.debug('OTA finished downloading')

            store.commit('overTheAirUpdates/setStatusCode', 6)
          } else {
            store.commit('overTheAirUpdates/setStatusCode', 0)
          }
        } catch (error) {
          $log.debug('OTA threw error', error)

          store.commit('overTheAirUpdates/setStatusCode', 0)
        }
      } else if (!isActive && data && !isOnAuthPage) {
        $log.debug('OTA: Setting new version')
        // Do the switch when user leave app or when you want
        SplashScreen.show()

        try {
          store.commit('overTheAirUpdates/setStatusCode', 8)

          await CapacitorUpdater.set({ id: data.id })

          store.commit('overTheAirUpdates/setStatusCode', 1)

          redirect('/platform')
        } catch (error) {
          $log.debug('OTA error', error)

          store.commit('overTheAirUpdates/setStatusCode', 3)

          store.commit('overTheAirUpdates/setError', error)

          SplashScreen.hide()
        }
      }
    }

    otaUpdates.setUserId = async userId => {
      if (!userId) {
        return false
      }

      await CapacitorUpdater.setCustomId({ customId: userId })
    }

    otaUpdates.sync(true)

    const { App } = await import('@capacitor/app')

    App.addListener('appStateChange', appState => {
      otaUpdates.sync(appState.isActive)
    })
  }

  inject('otaUpdates', otaUpdates)
}
