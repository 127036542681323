export function getCurrentPosition() {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        maximumAge: 11000,
        timeout: 10000
      })
    })
  } else {
    throw new Error('Geolocation is not supported on your device')
  }
}
