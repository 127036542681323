import { locationTypes } from 'exchange-common/geo/locationTypes'
import { set } from 'lodash'

import { getCurrentPosition } from '~/lib/geo/currentPosition'
import { ApiModel } from '~/plugins/api/model'
import { date } from '~/plugins/date'
import { isNative } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    sendLocationLogsApiModel: new ApiModel(),
    locationApiModel: new ApiModel(),
    locationTracking: {
      history: [],
      currentLocation: {
        lngLat: null,
        heading: 0,
        accuracyMeters: 0,
        lastUpdatedTimestamp: 0
      },
      isActive: false,
      isDisabled: false,
      hasDeniedPermissions: false
    }
  }
}

export const state = () => initialState()

export const getters = {
  currentLocation(state) {
    return state.locationTracking.currentLocation
  },

  isLocationTrackingActive: state => {
    // Is active and current location was updated in the last 2 minutes

    return (
      state.locationTracking.isActive &&
      date().diff(date(state.locationTracking.currentLocation.lastUpdatedTimestamp), 'second') < 120
    )
  },

  shouldReuseCurrentLocation: (state, getters) => {
    // If location tracking is active and current location was updated in the last 20 seconds
    return (
      state.locationTracking.isActive &&
      date().diff(date(state.locationTracking.currentLocation.lastUpdatedTimestamp), 'second') < 20
    )
  },

  hasDeniedLocationTrackingPermission: state => {
    return state.locationTracking.hasDeniedPermissions || state.locationTracking.isDisabled
  },

  isLocationApiLoading: state => {
    return state.locationApiModel?.isLoading || false
  }
}

export const actions = {
  async sendLocationLogs({ state, commit, rootGetters }) {
    if (
      state.locationTracking.history.length === 0 ||
      !rootGetters['app/getApp'].isOnline ||
      !rootGetters['app/getApp'].isAuth
    ) {
      return false
    }

    // Process all logs in the queue and remove them
    const batch = [...state.locationTracking.history]

    commit('clearLocationHistory')

    try {
      await this.$api
        .geo(state.sendLocationLogsApiModel)
        .useStorePath('geo.sendLocationLogsApiModel')
        .sendLocationLogs(batch)
    } catch (error) {
      this.$log.error('Error sending location logs', error)

      // Re-add failed logs back into the queue
      commit('addFailedBatchToLocationHistory', batch)
    }
  },

  async getCurrentPosition({ commit, dispatch, getters, rootGetters }) {
    if (getters.shouldReuseCurrentLocation) {
      return getters.currentLocation
    } else {
      let position = null

      if (isNative) {
        position = await this.$locationTracking.getCurrentPosition()
      } else {
        position = await getCurrentPosition()
      }

      if (position) {
        commit('addNewPosition', {
          position: {
            coords: {
              // You can't spread a window navigator's position object so assign manually
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
              accuracy: position.coords.accuracy,
              altitude: position.coords.altitude,
              altitudeAccuracy: position.coords.altitudeAccuracy,
              heading: position.coords.heading,
              speed: position.coords.speed
            },
            type: locationTypes.USER_LOCATION,
            recordedAt: this.$date.utc().toISOString(),
            organisationId: rootGetters['app/getApp'].currentOrganisation?.id,
            farmId: rootGetters['farm/farm']?.id
          },
          addToHistory: true
        })

        // Data structure needs to match state currentLocation
        return {
          lngLat: [position.coords.longitude, position.coords.latitude],
          heading: position.coords.heading,
          accuracyMeters: position.coords.accuracy,
          lastUpdatedTimestamp: position.timestamp
        }
      } else {
        throw new Error('Unable to find current position')
      }
    }
  },

  async addLocation({ state }, location) {
    try {
      await this.$api.geo(state.locationApiModel).useStorePath('geo.locationApiModel').addLocation(location)
      return { success: true }
    } catch (error) {
      this.$log.error('Error sending new location', location)
      return { success: false }
    }
  },

  async removeLocation({ state }, locationId) {
    try {
      await this.$api.geo(state.locationApiModel).useStorePath('geo.locationApiModel').removeLocation(locationId)
      return { success: true }
    } catch (error) {
      this.$log.error('Error deleting location', error)
      return { success: false }
    }
  }
}

export const mutations = {
  addNewPosition(state, { position, addToHistory = true } = {}) {
    if (addToHistory) {
      state.locationTracking.history.push({
        lngLat: [position.coords.longitude, position.coords.latitude],
        heading: position.coords.heading,
        accuracyMeters: position.coords.accuracy,
        recordedAt: position.recordedAt,
        organisationId: position.organisationId,
        farmId: position.farmId,
        type: position.type
      })
    }

    if (position.coords?.accuracy) {
      state.locationTracking.currentLocation.lngLat = [position.coords.longitude, position.coords.latitude]
      state.locationTracking.currentLocation.lastUpdatedTimestamp = position.timestamp
      state.locationTracking.currentLocation.heading = position.coords.heading
      state.locationTracking.currentLocation.accuracyMeters = position.coords.accuracy
    }
  },

  addFailedBatchToLocationHistory(state, positions) {
    state.locationTracking.history = [...state.locationTracking.history, ...positions]
  },

  setLocationTrackingPermissionState(state, permissions) {
    if ('isActive' in permissions) {
      state.locationTracking.isActive = permissions.isActive
    }

    if ('isDisabled' in permissions) {
      state.locationTracking.isDisabled = permissions.isDisabled
    }

    if ('hasDeniedPermissions' in permissions) {
      state.locationTracking.hasDeniedPermissions = permissions.hasDeniedPermissions
    }
  },

  clearLocationHistory(state) {
    set(state.locationTracking, 'history', [])
  },

  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  reset(state) {
    this.$log.debug('Resetting Geo module')

    Object.assign(state, initialState())
  }
}
