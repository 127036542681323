export const BannerFundingTool = () => import('../../src/components/banners/BannerFundingTool.vue' /* webpackChunkName: "components/banner-funding-tool" */).then(c => wrapFunctional(c.default || c))
export const BannerMobileApp = () => import('../../src/components/banners/BannerMobileApp.vue' /* webpackChunkName: "components/banner-mobile-app" */).then(c => wrapFunctional(c.default || c))
export const BannerOffline = () => import('../../src/components/banners/BannerOffline.vue' /* webpackChunkName: "components/banner-offline" */).then(c => wrapFunctional(c.default || c))
export const BannerPushNotifications = () => import('../../src/components/banners/BannerPushNotifications.vue' /* webpackChunkName: "components/banner-push-notifications" */).then(c => wrapFunctional(c.default || c))
export const BannerSync = () => import('../../src/components/banners/BannerSync.vue' /* webpackChunkName: "components/banner-sync" */).then(c => wrapFunctional(c.default || c))
export const NavItems = () => import('../../src/components/nav/NavItems.vue' /* webpackChunkName: "components/nav-items" */).then(c => wrapFunctional(c.default || c))
export const ArticleCard = () => import('../../src/components/elements/content/ArticleCard.vue' /* webpackChunkName: "components/article-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleFull = () => import('../../src/components/elements/content/ArticleFull.vue' /* webpackChunkName: "components/article-full" */).then(c => wrapFunctional(c.default || c))
export const ArticlesContainer = () => import('../../src/components/elements/content/ArticlesContainer.vue' /* webpackChunkName: "components/articles-container" */).then(c => wrapFunctional(c.default || c))
export const LayoutArticles = () => import('../../src/components/elements/content/LayoutArticles.vue' /* webpackChunkName: "components/layout-articles" */).then(c => wrapFunctional(c.default || c))
export const ContentEditor = () => import('../../src/components/elements/content-editor/ContentEditor.vue' /* webpackChunkName: "components/content-editor" */).then(c => wrapFunctional(c.default || c))
export const FundingDuration = () => import('../../src/components/elements/funding/FundingDuration.vue' /* webpackChunkName: "components/funding-duration" */).then(c => wrapFunctional(c.default || c))
export const FundingProgrammeList = () => import('../../src/components/elements/funding/FundingProgrammeList.vue' /* webpackChunkName: "components/funding-programme-list" */).then(c => wrapFunctional(c.default || c))
export const BreakdownTabs = () => import('../../src/components/elements/platform/BreakdownTabs.vue' /* webpackChunkName: "components/breakdown-tabs" */).then(c => wrapFunctional(c.default || c))
export const CalculationAndImprovement = () => import('../../src/components/elements/platform/CalculationAndImprovement.vue' /* webpackChunkName: "components/calculation-and-improvement" */).then(c => wrapFunctional(c.default || c))
export const MapWithFields = () => import('../../src/components/elements/platform/MapWithFields.vue' /* webpackChunkName: "components/map-with-fields" */).then(c => wrapFunctional(c.default || c))
export const MapWithHabitats = () => import('../../src/components/elements/platform/MapWithHabitats.vue' /* webpackChunkName: "components/map-with-habitats" */).then(c => wrapFunctional(c.default || c))
export const MapWithHedgerows = () => import('../../src/components/elements/platform/MapWithHedgerows.vue' /* webpackChunkName: "components/map-with-hedgerows" */).then(c => wrapFunctional(c.default || c))
export const MapWithMarkers = () => import('../../src/components/elements/platform/MapWithMarkers.vue' /* webpackChunkName: "components/map-with-markers" */).then(c => wrapFunctional(c.default || c))
export const ReportIntro = () => import('../../src/components/elements/platform/ReportIntro.vue' /* webpackChunkName: "components/report-intro" */).then(c => wrapFunctional(c.default || c))
export const ReportScoreBar = () => import('../../src/components/elements/platform/ReportScoreBar.vue' /* webpackChunkName: "components/report-score-bar" */).then(c => wrapFunctional(c.default || c))
export const ReportScoreCard = () => import('../../src/components/elements/platform/ReportScoreCard.vue' /* webpackChunkName: "components/report-score-card" */).then(c => wrapFunctional(c.default || c))
export const ReportText = () => import('../../src/components/elements/platform/ReportText.vue' /* webpackChunkName: "components/report-text" */).then(c => wrapFunctional(c.default || c))
export const ScoreBreakdownTable = () => import('../../src/components/elements/platform/ScoreBreakdownTable.vue' /* webpackChunkName: "components/score-breakdown-table" */).then(c => wrapFunctional(c.default || c))
export const Percent = () => import('../../src/components/elements/report/Percent.vue' /* webpackChunkName: "components/percent" */).then(c => wrapFunctional(c.default || c))
export const SamplingChecklist = () => import('../../src/components/elements/sampling/SamplingChecklist.vue' /* webpackChunkName: "components/sampling-checklist" */).then(c => wrapFunctional(c.default || c))
export const SamplingHelpLink = () => import('../../src/components/elements/sampling/SamplingHelpLink.vue' /* webpackChunkName: "components/sampling-help-link" */).then(c => wrapFunctional(c.default || c))
export const SamplingParcelWrapper = () => import('../../src/components/elements/sampling/SamplingParcelWrapper.vue' /* webpackChunkName: "components/sampling-parcel-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SamplingProgress = () => import('../../src/components/elements/sampling/SamplingProgress.vue' /* webpackChunkName: "components/sampling-progress" */).then(c => wrapFunctional(c.default || c))
export const FormChangePassword = () => import('../../src/components/forms/auth/FormChangePassword.vue' /* webpackChunkName: "components/form-change-password" */).then(c => wrapFunctional(c.default || c))
export const FormForgottenPassword = () => import('../../src/components/forms/auth/FormForgottenPassword.vue' /* webpackChunkName: "components/form-forgotten-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../src/components/forms/auth/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../src/components/forms/auth/FormResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormContent = () => import('../../src/components/forms/content/FormContent.vue' /* webpackChunkName: "components/form-content" */).then(c => wrapFunctional(c.default || c))
export const FormFunding = () => import('../../src/components/forms/content/FormFunding.vue' /* webpackChunkName: "components/form-funding" */).then(c => wrapFunctional(c.default || c))
export const FormFundingBody = () => import('../../src/components/forms/content/FormFundingBody.vue' /* webpackChunkName: "components/form-funding-body" */).then(c => wrapFunctional(c.default || c))
export const FormFundingLandType = () => import('../../src/components/forms/content/FormFundingLandType.vue' /* webpackChunkName: "components/form-funding-land-type" */).then(c => wrapFunctional(c.default || c))
export const FormFundingPractice = () => import('../../src/components/forms/content/FormFundingPractice.vue' /* webpackChunkName: "components/form-funding-practice" */).then(c => wrapFunctional(c.default || c))
export const FormFundingProgramme = () => import('../../src/components/forms/content/FormFundingProgramme.vue' /* webpackChunkName: "components/form-funding-programme" */).then(c => wrapFunctional(c.default || c))
export const FormFundingScheme = () => import('../../src/components/forms/content/FormFundingScheme.vue' /* webpackChunkName: "components/form-funding-scheme" */).then(c => wrapFunctional(c.default || c))
export const FormQuickWin = () => import('../../src/components/forms/content/FormQuickWin.vue' /* webpackChunkName: "components/form-quick-win" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../src/components/forms/cookie/cookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const FormFarm = () => import('../../src/components/forms/farm/FormFarm.vue' /* webpackChunkName: "components/form-farm" */).then(c => wrapFunctional(c.default || c))
export const FormHedgerowSetup = () => import('../../src/components/forms/farm-setup/FormHedgerowSetup.vue' /* webpackChunkName: "components/form-hedgerow-setup" */).then(c => wrapFunctional(c.default || c))
export const GroupedSamplesList = () => import('../../src/components/forms/field-sampling/GroupedSamplesList.vue' /* webpackChunkName: "components/grouped-samples-list" */).then(c => wrapFunctional(c.default || c))
export const InteractiveListItem = () => import('../../src/components/forms/field-sampling/InteractiveListItem.vue' /* webpackChunkName: "components/interactive-list-item" */).then(c => wrapFunctional(c.default || c))
export const LocationSelector = () => import('../../src/components/forms/field-sampling/LocationSelector.vue' /* webpackChunkName: "components/location-selector" */).then(c => wrapFunctional(c.default || c))
export const SampleListEntry = () => import('../../src/components/forms/field-sampling/SampleListEntry.vue' /* webpackChunkName: "components/sample-list-entry" */).then(c => wrapFunctional(c.default || c))
export const SamplesList = () => import('../../src/components/forms/field-sampling/SamplesList.vue' /* webpackChunkName: "components/samples-list" */).then(c => wrapFunctional(c.default || c))
export const FormFundingContact = () => import('../../src/components/forms/funding-contact/FormFundingContact.vue' /* webpackChunkName: "components/form-funding-contact" */).then(c => wrapFunctional(c.default || c))
export const FormFundingFeedback = () => import('../../src/components/forms/funding-contact/FormFundingFeedback.vue' /* webpackChunkName: "components/form-funding-feedback" */).then(c => wrapFunctional(c.default || c))
export const FormBiodiversityLabResults = () => import('../../src/components/forms/lab-results/FormBiodiversityLabResults.vue' /* webpackChunkName: "components/form-biodiversity-lab-results" */).then(c => wrapFunctional(c.default || c))
export const FormClimateChangeLabResults = () => import('../../src/components/forms/lab-results/FormClimateChangeLabResults.vue' /* webpackChunkName: "components/form-climate-change-lab-results" */).then(c => wrapFunctional(c.default || c))
export const FormClimateChangeSequestration = () => import('../../src/components/forms/lab-results/FormClimateChangeSequestration.vue' /* webpackChunkName: "components/form-climate-change-sequestration" */).then(c => wrapFunctional(c.default || c))
export const FormFoodProduction = () => import('../../src/components/forms/lab-results/FormFoodProduction.vue' /* webpackChunkName: "components/form-food-production" */).then(c => wrapFunctional(c.default || c))
export const FormSoilCover = () => import('../../src/components/forms/lab-results/FormSoilCover.vue' /* webpackChunkName: "components/form-soil-cover" */).then(c => wrapFunctional(c.default || c))
export const FormSoilLabResults = () => import('../../src/components/forms/lab-results/FormSoilLabResults.vue' /* webpackChunkName: "components/form-soil-lab-results" */).then(c => wrapFunctional(c.default || c))
export const FormWaterLabResults = () => import('../../src/components/forms/lab-results/FormWaterLabResults.vue' /* webpackChunkName: "components/form-water-lab-results" */).then(c => wrapFunctional(c.default || c))
export const FormWaterResults = () => import('../../src/components/forms/lab-results/FormWaterResults.vue' /* webpackChunkName: "components/form-water-results" */).then(c => wrapFunctional(c.default || c))
export const FormOrganisation = () => import('../../src/components/forms/organisation/FormOrganisation.vue' /* webpackChunkName: "components/form-organisation" */).then(c => wrapFunctional(c.default || c))
export const FormPartnerFarm = () => import('../../src/components/forms/partner-farm/FormPartnerFarm.vue' /* webpackChunkName: "components/form-partner-farm" */).then(c => wrapFunctional(c.default || c))
export const FormSurvey = () => import('../../src/components/forms/survey/FormSurvey.vue' /* webpackChunkName: "components/form-survey" */).then(c => wrapFunctional(c.default || c))
export const SurveyPage = () => import('../../src/components/forms/survey/SurveyPage.vue' /* webpackChunkName: "components/survey-page" */).then(c => wrapFunctional(c.default || c))
export const FormTeam = () => import('../../src/components/forms/team/FormTeam.vue' /* webpackChunkName: "components/form-team" */).then(c => wrapFunctional(c.default || c))
export const FormProfile = () => import('../../src/components/forms/user/FormProfile.vue' /* webpackChunkName: "components/form-profile" */).then(c => wrapFunctional(c.default || c))
export const FormRegistration = () => import('../../src/components/forms/user/FormRegistration.vue' /* webpackChunkName: "components/form-registration" */).then(c => wrapFunctional(c.default || c))
export const GuardAuth = () => import('../../src/components/guards/auth/GuardAuth.vue' /* webpackChunkName: "components/guard-auth" */).then(c => wrapFunctional(c.default || c))
export const Guard = () => import('../../src/components/guards/guard/Guard.vue' /* webpackChunkName: "components/guard" */).then(c => wrapFunctional(c.default || c))
export const GuardOrganisation = () => import('../../src/components/guards/organisation/GuardOrganisation.vue' /* webpackChunkName: "components/guard-organisation" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../src/components/layouts/app-loader/AppLoader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const LayoutAuth = () => import('../../src/components/layouts/auth/LayoutAuth.vue' /* webpackChunkName: "components/layout-auth" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../src/components/layouts/footer/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const ModalBase = () => import('../../src/components/modals/modal-base/ModalBase.vue' /* webpackChunkName: "components/modal-base" */).then(c => wrapFunctional(c.default || c))
export const ModalWrapper = () => import('../../src/components/modals/modal-wrapper/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ApiData = () => import('../../src/components/utils/api-data/ApiData.vue' /* webpackChunkName: "components/api-data" */).then(c => wrapFunctional(c.default || c))
export const ApiLoadMore = () => import('../../src/components/utils/api-load-more/ApiLoadMore.vue' /* webpackChunkName: "components/api-load-more" */).then(c => wrapFunctional(c.default || c))
export const DevDebug = () => import('../../src/components/utils/dev-debug/DevDebug.vue' /* webpackChunkName: "components/dev-debug" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbed = () => import('../../src/components/utils/iframe-embed/IframeEmbed.vue' /* webpackChunkName: "components/iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../src/components/utils/loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ScriptLoader = () => import('../../src/components/utils/script-loader/ScriptLoader.vue' /* webpackChunkName: "components/script-loader" */).then(c => wrapFunctional(c.default || c))
export const ActiveDataFilters = () => import('../../src/components/elements/admin/active-data-filters/ActiveDataFilters.vue' /* webpackChunkName: "components/active-data-filters" */).then(c => wrapFunctional(c.default || c))
export const AdminSectionHeader = () => import('../../src/components/elements/admin/section-header/AdminSectionHeader.vue' /* webpackChunkName: "components/admin-section-header" */).then(c => wrapFunctional(c.default || c))
export const AdminUserCard = () => import('../../src/components/elements/admin/user-card/AdminUserCard.vue' /* webpackChunkName: "components/admin-user-card" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../src/components/elements/base/accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionCard = () => import('../../src/components/elements/base/accordion/AccordionCard.vue' /* webpackChunkName: "components/accordion-card" */).then(c => wrapFunctional(c.default || c))
export const AccordionList = () => import('../../src/components/elements/base/accordion/AccordionList.vue' /* webpackChunkName: "components/accordion-list" */).then(c => wrapFunctional(c.default || c))
export const AppWrapper = () => import('../../src/components/elements/base/app-wrapper/AppWrapper.vue' /* webpackChunkName: "components/app-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../src/components/elements/base/avatar/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const AvatarGroup = () => import('../../src/components/elements/base/avatar/AvatarGroup.vue' /* webpackChunkName: "components/avatar-group" */).then(c => wrapFunctional(c.default || c))
export const AvatarPlaceholder = () => import('../../src/components/elements/base/avatar/AvatarPlaceholder.vue' /* webpackChunkName: "components/avatar-placeholder" */).then(c => wrapFunctional(c.default || c))
export const BadgeCount = () => import('../../src/components/elements/base/badge-count/BadgeCount.vue' /* webpackChunkName: "components/badge-count" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../src/components/elements/base/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BottomSheet = () => import('../../src/components/elements/base/bottom-sheet/BottomSheet.vue' /* webpackChunkName: "components/bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../src/components/elements/base/breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/elements/base/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonDropdown = () => import('../../src/components/elements/base/button-dropdown/ButtonDropdown.vue' /* webpackChunkName: "components/button-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ButtonSwipe = () => import('../../src/components/elements/base/button-swipe/ButtonSwipe.vue' /* webpackChunkName: "components/button-swipe" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../src/components/elements/base/contact-card/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactNumber = () => import('../../src/components/elements/base/contact-number/ContactNumber.vue' /* webpackChunkName: "components/contact-number" */).then(c => wrapFunctional(c.default || c))
export const ContentSwitcher = () => import('../../src/components/elements/base/content-switcher/ContentSwitcher.vue' /* webpackChunkName: "components/content-switcher" */).then(c => wrapFunctional(c.default || c))
export const DateDisplay = () => import('../../src/components/elements/base/date-display/DateDisplay.vue' /* webpackChunkName: "components/date-display" */).then(c => wrapFunctional(c.default || c))
export const DevSiteBanner = () => import('../../src/components/elements/base/dev-site-banner/DevSiteBanner.vue' /* webpackChunkName: "components/dev-site-banner" */).then(c => wrapFunctional(c.default || c))
export const DistanceDisplay = () => import('../../src/components/elements/base/distance-display/DistanceDisplay.vue' /* webpackChunkName: "components/distance-display" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenuList = () => import('../../src/components/elements/base/dropdown-menu-list/DropdownMenuList.vue' /* webpackChunkName: "components/dropdown-menu-list" */).then(c => wrapFunctional(c.default || c))
export const MobileDropdownMenuOverlay = () => import('../../src/components/elements/base/dropdown-menu-list/MobileDropdownMenuOverlay.vue' /* webpackChunkName: "components/mobile-dropdown-menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../src/components/elements/base/empty/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../src/components/elements/base/icon/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../src/components/elements/base/image/Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../src/components/elements/base/link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const LinkTile = () => import('../../src/components/elements/base/link-tile/LinkTile.vue' /* webpackChunkName: "components/link-tile" */).then(c => wrapFunctional(c.default || c))
export const LinkedText = () => import('../../src/components/elements/base/linked-text/LinkedText.vue' /* webpackChunkName: "components/linked-text" */).then(c => wrapFunctional(c.default || c))
export const Markdown = () => import('../../src/components/elements/base/markdown/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c))
export const AccountNotification = () => import('../../src/components/elements/base/notification/AccountNotification.vue' /* webpackChunkName: "components/account-notification" */).then(c => wrapFunctional(c.default || c))
export const AccountNotificationWrapper = () => import('../../src/components/elements/base/notification/AccountNotificationWrapper.vue' /* webpackChunkName: "components/account-notification-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../src/components/elements/base/notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloating = () => import('../../src/components/elements/base/notification/NotificationFloating.vue' /* webpackChunkName: "components/notification-floating" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloatingWrapper = () => import('../../src/components/elements/base/notification/NotificationFloatingWrapper.vue' /* webpackChunkName: "components/notification-floating-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/elements/base/pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Panel = () => import('../../src/components/elements/base/panel/Panel.vue' /* webpackChunkName: "components/panel" */).then(c => wrapFunctional(c.default || c))
export const Placeholder = () => import('../../src/components/elements/base/placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../src/components/elements/base/popover/Popover.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/elements/base/progress-bar/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProgressCircle = () => import('../../src/components/elements/base/progress-circle/ProgressCircle.vue' /* webpackChunkName: "components/progress-circle" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../src/components/elements/base/rating/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const SelectList = () => import('../../src/components/elements/base/select-list/SelectList.vue' /* webpackChunkName: "components/select-list" */).then(c => wrapFunctional(c.default || c))
export const SelectListItem = () => import('../../src/components/elements/base/select-list/SelectListItem.vue' /* webpackChunkName: "components/select-list-item" */).then(c => wrapFunctional(c.default || c))
export const SelectTileItem = () => import('../../src/components/elements/base/select-tile-item/SelectTileItem.vue' /* webpackChunkName: "components/select-tile-item" */).then(c => wrapFunctional(c.default || c))
export const Stat = () => import('../../src/components/elements/base/stat/Stat.vue' /* webpackChunkName: "components/stat" */).then(c => wrapFunctional(c.default || c))
export const StatusTag = () => import('../../src/components/elements/base/status-tag/StatusTag.vue' /* webpackChunkName: "components/status-tag" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../src/components/elements/base/table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../src/components/elements/base/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsEmbeddedContent = () => import('../../src/components/elements/base/tabs-embedded-content/TabsEmbeddedContent.vue' /* webpackChunkName: "components/tabs-embedded-content" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../src/components/elements/base/tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const ToolTip = () => import('../../src/components/elements/base/tooltip/ToolTip.vue' /* webpackChunkName: "components/tool-tip" */).then(c => wrapFunctional(c.default || c))
export const TotalPill = () => import('../../src/components/elements/base/total-pill/TotalPill.vue' /* webpackChunkName: "components/total-pill" */).then(c => wrapFunctional(c.default || c))
export const WeightDisplay = () => import('../../src/components/elements/base/weight-display/WeightDisplay.vue' /* webpackChunkName: "components/weight-display" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../src/components/elements/financial/currency-display/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../src/components/elements/form/date-picker/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const ErrorSummary = () => import('../../src/components/elements/form/error-summary/ErrorSummary.vue' /* webpackChunkName: "components/error-summary" */).then(c => wrapFunctional(c.default || c))
export const Field = () => import('../../src/components/elements/form/field/Field.vue' /* webpackChunkName: "components/field" */).then(c => wrapFunctional(c.default || c))
export const FileUploadCore = () => import('../../src/components/elements/form/file-upload/FileUploadCore.vue' /* webpackChunkName: "components/file-upload-core" */).then(c => wrapFunctional(c.default || c))
export const FileUploadInput = () => import('../../src/components/elements/form/file-upload/FileUploadInput.vue' /* webpackChunkName: "components/file-upload-input" */).then(c => wrapFunctional(c.default || c))
export const FileUploadList = () => import('../../src/components/elements/form/file-upload/FileUploadList.vue' /* webpackChunkName: "components/file-upload-list" */).then(c => wrapFunctional(c.default || c))
export const FileUploadListItem = () => import('../../src/components/elements/form/file-upload/FileUploadListItem.vue' /* webpackChunkName: "components/file-upload-list-item" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../src/components/elements/form/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormStep = () => import('../../src/components/elements/form/form-step/FormStep.vue' /* webpackChunkName: "components/form-step" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../src/components/elements/form/input/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputLinks = () => import('../../src/components/elements/form/input-links/InputLinks.vue' /* webpackChunkName: "components/input-links" */).then(c => wrapFunctional(c.default || c))
export const Label = () => import('../../src/components/elements/form/label/Label.vue' /* webpackChunkName: "components/label" */).then(c => wrapFunctional(c.default || c))
export const MultiSelect = () => import('../../src/components/elements/form/multi-select/MultiSelect.vue' /* webpackChunkName: "components/multi-select" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../src/components/elements/form/select/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const TagInput = () => import('../../src/components/elements/form/tags/TagInput.vue' /* webpackChunkName: "components/tag-input" */).then(c => wrapFunctional(c.default || c))
export const TextArea = () => import('../../src/components/elements/form/text-area/TextArea.vue' /* webpackChunkName: "components/text-area" */).then(c => wrapFunctional(c.default || c))
export const TimePicker = () => import('../../src/components/elements/form/time-picker/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../src/components/elements/form/toggle/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const ToggleArray = () => import('../../src/components/elements/form/toggle-array/ToggleArray.vue' /* webpackChunkName: "components/toggle-array" */).then(c => wrapFunctional(c.default || c))
export const ToggleCard = () => import('../../src/components/elements/form/toggle-card/ToggleCard.vue' /* webpackChunkName: "components/toggle-card" */).then(c => wrapFunctional(c.default || c))
export const ToggleCountries = () => import('../../src/components/elements/form/toggle-countries/ToggleCountries.vue' /* webpackChunkName: "components/toggle-countries" */).then(c => wrapFunctional(c.default || c))
export const ToggleCountry = () => import('../../src/components/elements/form/toggle-countries/ToggleCountry.vue' /* webpackChunkName: "components/toggle-country" */).then(c => wrapFunctional(c.default || c))
export const ToggleDimensions = () => import('../../src/components/elements/form/toggle-dimensions/ToggleDimensions.vue' /* webpackChunkName: "components/toggle-dimensions" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../src/components/elements/geo/address/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressLocalityRegion = () => import('../../src/components/elements/geo/address-locality-region/AddressLocalityRegion.vue' /* webpackChunkName: "components/address-locality-region" */).then(c => wrapFunctional(c.default || c))
export const CurrentLocationAnimation = () => import('../../src/components/elements/geo/current-location-animation/CurrentLocationAnimation.vue' /* webpackChunkName: "components/current-location-animation" */).then(c => wrapFunctional(c.default || c))
export const Flag = () => import('../../src/components/elements/geo/flag/Flag.vue' /* webpackChunkName: "components/flag" */).then(c => wrapFunctional(c.default || c))
export const LocaleSwitcher = () => import('../../src/components/elements/geo/locale-switcher/LocaleSwitcher.vue' /* webpackChunkName: "components/locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const LocationArrow = () => import('../../src/components/elements/geo/location-arrow/LocationArrow.vue' /* webpackChunkName: "components/location-arrow" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../src/components/elements/geo/map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MapImage = () => import('../../src/components/elements/geo/map/MapImage.vue' /* webpackChunkName: "components/map-image" */).then(c => wrapFunctional(c.default || c))
export const ContextualDataAnalysisCategory = () => import('../../src/components/elements/map/contextual-data/ContextualDataAnalysisCategory.vue' /* webpackChunkName: "components/contextual-data-analysis-category" */).then(c => wrapFunctional(c.default || c))
export const ContextualDataAnalysisGroup = () => import('../../src/components/elements/map/contextual-data/ContextualDataAnalysisGroup.vue' /* webpackChunkName: "components/contextual-data-analysis-group" */).then(c => wrapFunctional(c.default || c))
export const ContextualDataSummary = () => import('../../src/components/elements/map/contextual-data/ContextualDataSummary.vue' /* webpackChunkName: "components/contextual-data-summary" */).then(c => wrapFunctional(c.default || c))
export const LayerInfo = () => import('../../src/components/elements/map/contextual-data/LayerInfo.vue' /* webpackChunkName: "components/layer-info" */).then(c => wrapFunctional(c.default || c))
export const FarmParcelSummary = () => import('../../src/components/elements/map/farm-setup/FarmParcelSummary.vue' /* webpackChunkName: "components/farm-parcel-summary" */).then(c => wrapFunctional(c.default || c))
export const FarmSetup = () => import('../../src/components/elements/map/farm-setup/FarmSetup.vue' /* webpackChunkName: "components/farm-setup" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupBiodiversity = () => import('../../src/components/elements/map/farm-setup/FarmSetupBiodiversity.vue' /* webpackChunkName: "components/farm-setup-biodiversity" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupFootpaths = () => import('../../src/components/elements/map/farm-setup/FarmSetupFootpaths.vue' /* webpackChunkName: "components/farm-setup-footpaths" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupHedgerows = () => import('../../src/components/elements/map/farm-setup/FarmSetupHedgerows.vue' /* webpackChunkName: "components/farm-setup-hedgerows" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupParcels = () => import('../../src/components/elements/map/farm-setup/FarmSetupParcels.vue' /* webpackChunkName: "components/farm-setup-parcels" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupParcelsInfieldHabitats = () => import('../../src/components/elements/map/farm-setup/FarmSetupParcelsInfieldHabitats.vue' /* webpackChunkName: "components/farm-setup-parcels-infield-habitats" */).then(c => wrapFunctional(c.default || c))
export const FarmSetupZonation = () => import('../../src/components/elements/map/farm-setup/FarmSetupZonation.vue' /* webpackChunkName: "components/farm-setup-zonation" */).then(c => wrapFunctional(c.default || c))
export const MapWithContextualData = () => import('../../src/components/elements/map/map-with-contextual-data/MapWithContextualData.vue' /* webpackChunkName: "components/map-with-contextual-data" */).then(c => wrapFunctional(c.default || c))
export const ActionPlanItemCard = () => import('../../src/components/elements/platform/action-plan/ActionPlanItemCard.vue' /* webpackChunkName: "components/action-plan-item-card" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../src/components/elements/platform/action-plan/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const RecommendationTags = () => import('../../src/components/elements/platform/action-plan/RecommendationTags.vue' /* webpackChunkName: "components/recommendation-tags" */).then(c => wrapFunctional(c.default || c))
export const Cumulative = () => import('../../src/components/elements/report/charts/Cumulative.vue' /* webpackChunkName: "components/cumulative" */).then(c => wrapFunctional(c.default || c))
export const DimensionNote = () => import('../../src/components/elements/report/dimension-note/DimensionNote.vue' /* webpackChunkName: "components/dimension-note" */).then(c => wrapFunctional(c.default || c))
export const DimensionScoreDoughnut = () => import('../../src/components/elements/report/dimension-score-doughnut/DimensionScoreDoughnut.vue' /* webpackChunkName: "components/dimension-score-doughnut" */).then(c => wrapFunctional(c.default || c))
export const DimensionWrapper = () => import('../../src/components/elements/report/dimension-wrapper/DimensionWrapper.vue' /* webpackChunkName: "components/dimension-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MetricWrapper = () => import('../../src/components/elements/report/metric-wrapper/MetricWrapper.vue' /* webpackChunkName: "components/metric-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SampleNotes = () => import('../../src/components/elements/report/sample-notes/SampleNotes.vue' /* webpackChunkName: "components/sample-notes" */).then(c => wrapFunctional(c.default || c))
export const SampleNotesGroupedZone = () => import('../../src/components/elements/report/sample-notes/SampleNotesGroupedZone.vue' /* webpackChunkName: "components/sample-notes-grouped-zone" */).then(c => wrapFunctional(c.default || c))
export const SetupChecklist = () => import('../../src/components/elements/report/setup-checklist/SetupChecklist.vue' /* webpackChunkName: "components/setup-checklist" */).then(c => wrapFunctional(c.default || c))
export const SubDimensionContainer = () => import('../../src/components/elements/report/subdimension-container/SubDimensionContainer.vue' /* webpackChunkName: "components/sub-dimension-container" */).then(c => wrapFunctional(c.default || c))
export const SummaryNote = () => import('../../src/components/elements/report/summary-note/SummaryNote.vue' /* webpackChunkName: "components/summary-note" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../src/components/elements/typography/heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const NativeStatusBar = () => import('../../src/components/layouts/native/status-bar/NativeStatusBar.vue' /* webpackChunkName: "components/native-status-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminContentHeader = () => import('../../src/components/layouts/shell/content-header/LayoutAdminContentHeader.vue' /* webpackChunkName: "components/layout-admin-content-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminDataIndex = () => import('../../src/components/layouts/shell/data-index/LayoutAdminDataIndex.vue' /* webpackChunkName: "components/layout-admin-data-index" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformHeader = () => import('../../src/components/layouts/shell/header/LayoutPlatformHeader.vue' /* webpackChunkName: "components/layout-platform-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformHeaderDesktop = () => import('../../src/components/layouts/shell/header/LayoutPlatformHeaderDesktop.vue' /* webpackChunkName: "components/layout-platform-header-desktop" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformHeaderMobile = () => import('../../src/components/layouts/shell/header/LayoutPlatformHeaderMobile.vue' /* webpackChunkName: "components/layout-platform-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutPlatformShell = () => import('../../src/components/layouts/shell/shell/LayoutPlatformShell.vue' /* webpackChunkName: "components/layout-platform-shell" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminSidebar = () => import('../../src/components/layouts/shell/sidebar/LayoutAdminSidebar.vue' /* webpackChunkName: "components/layout-admin-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ModalManageActionPlan = () => import('../../src/components/modals/action-plan/manage-action-plan/ModalManageActionPlan.vue' /* webpackChunkName: "components/modal-manage-action-plan" */).then(c => wrapFunctional(c.default || c))
export const ModalViewActionPlan = () => import('../../src/components/modals/action-plan/view-action-plan/ModalViewActionPlan.vue' /* webpackChunkName: "components/modal-view-action-plan" */).then(c => wrapFunctional(c.default || c))
export const ModalImpersonate = () => import('../../src/components/modals/admin/impersonate/ModalImpersonate.vue' /* webpackChunkName: "components/modal-impersonate" */).then(c => wrapFunctional(c.default || c))
export const ModalInternalNotes = () => import('../../src/components/modals/admin/internal-notes/ModalInternalNotes.vue' /* webpackChunkName: "components/modal-internal-notes" */).then(c => wrapFunctional(c.default || c))
export const ModalUserNotificationActivity = () => import('../../src/components/modals/admin/user-notification-activity/ModalUserNotificationActivity.vue' /* webpackChunkName: "components/modal-user-notification-activity" */).then(c => wrapFunctional(c.default || c))
export const ModalCreated = () => import('../../src/components/modals/content/created/ModalCreated.vue' /* webpackChunkName: "components/modal-created" */).then(c => wrapFunctional(c.default || c))
export const ModalImageUpload = () => import('../../src/components/modals/content/image-upload/ModalImageUpload.vue' /* webpackChunkName: "components/modal-image-upload" */).then(c => wrapFunctional(c.default || c))
export const ModalVideoUpload = () => import('../../src/components/modals/content/video-upload/ModalVideoUpload.vue' /* webpackChunkName: "components/modal-video-upload" */).then(c => wrapFunctional(c.default || c))
export const ModalConversionInfo = () => import('../../src/components/modals/food-production/conversion-info/ModalConversionInfo.vue' /* webpackChunkName: "components/modal-conversion-info" */).then(c => wrapFunctional(c.default || c))
export const ModalFundingContact = () => import('../../src/components/modals/funding/funding-contact/ModalFundingContact.vue' /* webpackChunkName: "components/modal-funding-contact" */).then(c => wrapFunctional(c.default || c))
export const ModalFundingFeedback = () => import('../../src/components/modals/funding/funding-feedback/ModalFundingFeedback.vue' /* webpackChunkName: "components/modal-funding-feedback" */).then(c => wrapFunctional(c.default || c))
export const ModalViewFunding = () => import('../../src/components/modals/funding/view-funding/ModalViewFunding.vue' /* webpackChunkName: "components/modal-view-funding" */).then(c => wrapFunctional(c.default || c))
export const ModalViewProgramme = () => import('../../src/components/modals/funding/view-programme/ModalViewProgramme.vue' /* webpackChunkName: "components/modal-view-programme" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../src/components/modals/global/confirm/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalDebug = () => import('../../src/components/modals/global/debug/ModalDebug.vue' /* webpackChunkName: "components/modal-debug" */).then(c => wrapFunctional(c.default || c))
export const ModalFilter = () => import('../../src/components/modals/global/filters/ModalFilter.vue' /* webpackChunkName: "components/modal-filter" */).then(c => wrapFunctional(c.default || c))
export const ModalHelp = () => import('../../src/components/modals/global/help/ModalHelp.vue' /* webpackChunkName: "components/modal-help" */).then(c => wrapFunctional(c.default || c))
export const ModalNotifications = () => import('../../src/components/modals/global/notifications/ModalNotifications.vue' /* webpackChunkName: "components/modal-notifications" */).then(c => wrapFunctional(c.default || c))
export const ModalTutorialHelp = () => import('../../src/components/modals/global/tutorial-help/ModalTutorialHelp.vue' /* webpackChunkName: "components/modal-tutorial-help" */).then(c => wrapFunctional(c.default || c))
export const ModalViewAboutMap = () => import('../../src/components/modals/map/view-about-map/ModalViewAboutMap.vue' /* webpackChunkName: "components/modal-view-about-map" */).then(c => wrapFunctional(c.default || c))
export const ModalFarmTypes = () => import('../../src/components/modals/organisation/farm-types/ModalFarmTypes.vue' /* webpackChunkName: "components/modal-farm-types" */).then(c => wrapFunctional(c.default || c))
export const ModalInviteNew = () => import('../../src/components/modals/organisation/invite-new/ModalInviteNew.vue' /* webpackChunkName: "components/modal-invite-new" */).then(c => wrapFunctional(c.default || c))
export const ModalSwitchOffline = () => import('../../src/components/modals/organisation/switch-offline/ModalSwitchOffline.vue' /* webpackChunkName: "components/modal-switch-offline" */).then(c => wrapFunctional(c.default || c))
export const ModalWelcome = () => import('../../src/components/modals/organisation/welcome/ModalWelcome.vue' /* webpackChunkName: "components/modal-welcome" */).then(c => wrapFunctional(c.default || c))
export const ModalManageRecommendation = () => import('../../src/components/modals/recommendation/manage-recommendation/ModalManageRecommendation.vue' /* webpackChunkName: "components/modal-manage-recommendation" */).then(c => wrapFunctional(c.default || c))
export const ModalViewRecommendation = () => import('../../src/components/modals/recommendation/view-recommendation/ModalViewRecommendation.vue' /* webpackChunkName: "components/modal-view-recommendation" */).then(c => wrapFunctional(c.default || c))
export const ModalEmptyReportWarning = () => import('../../src/components/modals/report/empty-report-warning/ModalEmptyReportWarning.vue' /* webpackChunkName: "components/modal-empty-report-warning" */).then(c => wrapFunctional(c.default || c))
export const ModalManageDimensionNote = () => import('../../src/components/modals/report/manage-dimension-note/ModalManageDimensionNote.vue' /* webpackChunkName: "components/modal-manage-dimension-note" */).then(c => wrapFunctional(c.default || c))
export const ModalManageSummaryNote = () => import('../../src/components/modals/report/manage-summary-note/ModalManageSummaryNote.vue' /* webpackChunkName: "components/modal-manage-summary-note" */).then(c => wrapFunctional(c.default || c))
export const ModalViewScoreCalculation = () => import('../../src/components/modals/report/view-score-calculation/ModalViewScoreCalculation.vue' /* webpackChunkName: "components/modal-view-score-calculation" */).then(c => wrapFunctional(c.default || c))
export const ModalViewScoreExplanation = () => import('../../src/components/modals/report/view-score-explanation/ModalViewScoreExplanation.vue' /* webpackChunkName: "components/modal-view-score-explanation" */).then(c => wrapFunctional(c.default || c))
export const ModalCheckIn = () => import('../../src/components/modals/sampling/check-in/ModalCheckIn.vue' /* webpackChunkName: "components/modal-check-in" */).then(c => wrapFunctional(c.default || c))
export const ModalDropPin = () => import('../../src/components/modals/sampling/drop-pin/ModalDropPin.vue' /* webpackChunkName: "components/modal-drop-pin" */).then(c => wrapFunctional(c.default || c))
export const ModalHedgerowMeasure = () => import('../../src/components/modals/sampling/hedgerow-measure/ModalHedgerowMeasure.vue' /* webpackChunkName: "components/modal-hedgerow-measure" */).then(c => wrapFunctional(c.default || c))
export const ModalPlantId = () => import('../../src/components/modals/sampling/plant-id/ModalPlantId.vue' /* webpackChunkName: "components/modal-plant-id" */).then(c => wrapFunctional(c.default || c))
export const ModalViewEntry = () => import('../../src/components/modals/sampling/view-entry/ModalViewEntry.vue' /* webpackChunkName: "components/modal-view-entry" */).then(c => wrapFunctional(c.default || c))
export const ModalFieldPicker = () => import('../../src/components/modals/setup/field-picker/ModalFieldPicker.vue' /* webpackChunkName: "components/modal-field-picker" */).then(c => wrapFunctional(c.default || c))
export const ModalHabitatPicker = () => import('../../src/components/modals/setup/habitat-picker/ModalHabitatPicker.vue' /* webpackChunkName: "components/modal-habitat-picker" */).then(c => wrapFunctional(c.default || c))
export const ModalParcels = () => import('../../src/components/modals/setup/parcels/ModalParcels.vue' /* webpackChunkName: "components/modal-parcels" */).then(c => wrapFunctional(c.default || c))
export const ModalParcelsDraw = () => import('../../src/components/modals/setup/parcels-draw/ModalParcelsDraw.vue' /* webpackChunkName: "components/modal-parcels-draw" */).then(c => wrapFunctional(c.default || c))
export const ModalParcelsImport = () => import('../../src/components/modals/setup/parcels-import/ModalParcelsImport.vue' /* webpackChunkName: "components/modal-parcels-import" */).then(c => wrapFunctional(c.default || c))
export const ModalParcelsImportSbi = () => import('../../src/components/modals/setup/parcels-import-sbi/ModalParcelsImportSbi.vue' /* webpackChunkName: "components/modal-parcels-import-sbi" */).then(c => wrapFunctional(c.default || c))
export const ModalParcelsImportShapefile = () => import('../../src/components/modals/setup/parcels-import-shapefile/ModalParcelsImportShapefile.vue' /* webpackChunkName: "components/modal-parcels-import-shapefile" */).then(c => wrapFunctional(c.default || c))
export const ModalZonationOverview = () => import('../../src/components/modals/setup/zonation-overview/ModalZonationOverview.vue' /* webpackChunkName: "components/modal-zonation-overview" */).then(c => wrapFunctional(c.default || c))
export const NavMobileFooter = () => import('../../src/components/nav/mobile/footer/NavMobileFooter.vue' /* webpackChunkName: "components/nav-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const NavMobileMenuItem = () => import('../../src/components/nav/mobile/menu-item/NavMobileMenuItem.vue' /* webpackChunkName: "components/nav-mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const SideBarAccordion = () => import('../../src/components/nav/sidebar/accordion/SideBarAccordion.vue' /* webpackChunkName: "components/side-bar-accordion" */).then(c => wrapFunctional(c.default || c))
export const NavSidebarItem = () => import('../../src/components/nav/sidebar/item/NavSidebarItem.vue' /* webpackChunkName: "components/nav-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbedModal = () => import('../../src/components/utils/iframe-embed/iframeEmbedModal.vue/IframeEmbedModal.vue' /* webpackChunkName: "components/iframe-embed-modal" */).then(c => wrapFunctional(c.default || c))
export const LoaderOverlay = () => import('../../src/components/utils/loader/LoaderOverlay/LoaderOverlay.vue' /* webpackChunkName: "components/loader-overlay" */).then(c => wrapFunctional(c.default || c))
export const StepsHorizontal = () => import('../../src/components/elements/base/steps/horizontal/StepsHorizontal.vue' /* webpackChunkName: "components/steps-horizontal" */).then(c => wrapFunctional(c.default || c))
export const UKHabAccordion = () => import('../../src/components/elements/form/complex-fields/ukhab/UKHabAccordion.vue' /* webpackChunkName: "components/u-k-hab-accordion" */).then(c => wrapFunctional(c.default || c))
export const UKHabPicker = () => import('../../src/components/elements/form/complex-fields/ukhab/UKHabPicker.vue' /* webpackChunkName: "components/u-k-hab-picker" */).then(c => wrapFunctional(c.default || c))
export const UKHabPrimaryPicker = () => import('../../src/components/elements/form/complex-fields/ukhab/UKHabPrimaryPicker.vue' /* webpackChunkName: "components/u-k-hab-primary-picker" */).then(c => wrapFunctional(c.default || c))
export const UKHabSecondaryPicker = () => import('../../src/components/elements/form/complex-fields/ukhab/UKHabSecondaryPicker.vue' /* webpackChunkName: "components/u-k-hab-secondary-picker" */).then(c => wrapFunctional(c.default || c))
export const TextAreaWithInnerControls = () => import('../../src/components/elements/form/text-area/templates/TextAreaWithInnerControls.vue' /* webpackChunkName: "components/text-area-with-inner-controls" */).then(c => wrapFunctional(c.default || c))
export const MapIcon = () => import('../../src/components/elements/geo/map/marker/MapIcon.vue' /* webpackChunkName: "components/map-icon" */).then(c => wrapFunctional(c.default || c))
export const ParcelAttributes = () => import('../../src/components/elements/map/farm-setup/parcel-attributes/ParcelAttributes.vue' /* webpackChunkName: "components/parcel-attributes" */).then(c => wrapFunctional(c.default || c))
export const ParcelAttributesStandingWater = () => import('../../src/components/elements/map/farm-setup/parcel-attributes/ParcelAttributesStandingWater.vue' /* webpackChunkName: "components/parcel-attributes-standing-water" */).then(c => wrapFunctional(c.default || c))
export const ModalCookies = () => import('../../src/components/modals/global/legal/cookies/ModalCookies.vue' /* webpackChunkName: "components/modal-cookies" */).then(c => wrapFunctional(c.default || c))
export const ModalPrivacy = () => import('../../src/components/modals/global/legal/privacy/ModalPrivacy.vue' /* webpackChunkName: "components/modal-privacy" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsAndConditionsForm = () => import('../../src/components/modals/global/legal/terms-and-conditions-form/ModalTermsAndConditionsForm.vue' /* webpackChunkName: "components/modal-terms-and-conditions-form" */).then(c => wrapFunctional(c.default || c))
export const ButtonContact = () => import('../../src/components/elements/base/button/templates/contact/ButtonContact.vue' /* webpackChunkName: "components/button-contact" */).then(c => wrapFunctional(c.default || c))
export const ButtonFilter = () => import('../../src/components/elements/base/button/templates/filter/ButtonFilter.vue' /* webpackChunkName: "components/button-filter" */).then(c => wrapFunctional(c.default || c))
export const ButtonRefresh = () => import('../../src/components/elements/base/button/templates/refresh/ButtonRefresh.vue' /* webpackChunkName: "components/button-refresh" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderAvatar = () => import('../../src/components/elements/base/placeholder/templates/avatar/PlaceholderAvatar.vue' /* webpackChunkName: "components/placeholder-avatar" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderLoadList = () => import('../../src/components/elements/base/placeholder/templates/load-list/PlaceholderLoadList.vue' /* webpackChunkName: "components/placeholder-load-list" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderMap = () => import('../../src/components/elements/base/placeholder/templates/map/PlaceholderMap.vue' /* webpackChunkName: "components/placeholder-map" */).then(c => wrapFunctional(c.default || c))
export const FileUploadAvatar = () => import('../../src/components/elements/form/file-upload/templates/avatar/FileUploadAvatar.vue' /* webpackChunkName: "components/file-upload-avatar" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../src/components/elements/form/file-upload/templates/default/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const AddressLookup = () => import('../../src/components/elements/form/input/templates/address-lookup/AddressLookup.vue' /* webpackChunkName: "components/address-lookup" */).then(c => wrapFunctional(c.default || c))
export const InputAddressLookup = () => import('../../src/components/elements/form/input/templates/address-lookup/InputAddressLookup.vue' /* webpackChunkName: "components/input-address-lookup" */).then(c => wrapFunctional(c.default || c))
export const InputCurrency = () => import('../../src/components/elements/form/input/templates/currency/InputCurrency.vue' /* webpackChunkName: "components/input-currency" */).then(c => wrapFunctional(c.default || c))
export const InputOther = () => import('../../src/components/elements/form/input/templates/other/InputOther.vue' /* webpackChunkName: "components/input-other" */).then(c => wrapFunctional(c.default || c))
export const FieldPassword = () => import('../../src/components/elements/form/input/templates/password/FieldPassword.vue' /* webpackChunkName: "components/field-password" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../src/components/elements/form/input/templates/search/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const InputTelephone = () => import('../../src/components/elements/form/input/templates/telephone/InputTelephone.vue' /* webpackChunkName: "components/input-telephone" */).then(c => wrapFunctional(c.default || c))
export const InputWhat3Words = () => import('../../src/components/elements/form/input/templates/w3w-lookup/InputWhat3Words.vue' /* webpackChunkName: "components/input-what3-words" */).then(c => wrapFunctional(c.default || c))
export const InputWeight = () => import('../../src/components/elements/form/input/templates/weight/InputWeight.vue' /* webpackChunkName: "components/input-weight" */).then(c => wrapFunctional(c.default || c))
export const InputSearchAutocomplete = () => import('../../src/components/elements/form/input/templates/search/autocomplete/InputSearchAutocomplete.vue' /* webpackChunkName: "components/input-search-autocomplete" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
