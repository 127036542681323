import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'
import { ContextualDataContainer } from 'exchange-common/contextualData/contextualDataContainer'
import { ContextualDataDefinitionContainer } from 'exchange-common/contextualData/contextualDataDefinitionContainer'
import { EdgeProximityDataEncoder } from 'exchange-common/contextualData/edgeProximityData'
import { locationTypes } from 'exchange-common/geo/locationTypes'
import { buildGraphFromJson } from 'exchange-common/utility/abstractGraph/jsonParsers'
import { cloneDeep, uniqBy } from 'lodash'
import safeJsonStringify from 'safe-json-stringify'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'
import { date } from '~/plugins/date'
import { isNative } from '~/plugins/native/capacitor'

function createInitialStateForOrganisation() {
  return {
    fetchFarmApi: new ApiModel(),
    fetchParcelHabitatsApi: new ApiModel(),
    contextualDataContainerApi: new ApiModel(),
    farm: {},
    parcelHabitats: [],
    hasForceHiddenLoader: false,
    farmLastUpdatedGeometryAt: null,
    contextualDataContainer: null
  }
}

const initialState = () => {
  return {
    organisations: {}
  }
}

let storeZones = []
let storeParcels = []

export const state = () => initialState()

export const getters = {
  currentOrganisationId(state, getters, rootState, rootGetters) {
    return rootGetters['auth/getCurrentOrganisation']?.id
  },

  fieldOptions(state, getters) {
    // UniqBy is a legacy problem where we used SBI parcel IDs which had duplicates in a single field
    return uniqBy(getters.parcels, 'parcelId').map(field => ({
      value: field.parcelId,
      label: field.parcelId
    }))
  },

  zoneOptions(state, getters) {
    // Using a unsused getter variable here to trigger reactivity when storeZones isn't part of the state
    /* eslint-disable-next-line no-unused-vars */
    const farmLastUpdated = getters.farmLastUpdatedGeometryAt

    return uniqBy(storeZones, 'zone').map(zone => ({
      value: zone.zone,
      label: zone.zone
    }))
  },

  parcels(state, getters) {
    // Using a unsused getter variable here to trigger reactivity when storeParcels isn't part of the state
    /* eslint-disable-next-line no-unused-vars */
    const farmLastUpdated = getters.farmLastUpdatedGeometryAt

    return storeParcels || []
  },

  // TODO: When we stop fetching all parcels from db and just return a count let's replace it here
  parcelCount(state, getters) {
    return getters.parcels.length
  },

  zones(state, getters) {
    // Using a unsused getter variable here to trigger reactivity when storeZones isn't part of the state
    /* eslint-disable-next-line no-unused-vars */
    const farmLastUpdated = getters.farmLastUpdatedGeometryAt

    return storeZones.filter(zone => zone?.zoneFeature?.geometry?.coordinates?.length > 0) || []
  },

  // TODO: When we stop fetching all parcels from db and just return a count let's replace it here
  zoneParcelCount(state, getters) {
    return getters.zones.length
  },

  farm(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.farm
      ? { ...state.organisations[getters.currentOrganisationId].farm, zones: storeZones, parcels: storeParcels }
      : {}
  },

  parcelHabitats(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.parcelHabitats || []
  },

  hasForceHiddenLoader(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.hasForceHiddenLoader
  },

  farmLastUpdatedGeometryAt(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.farmLastUpdatedGeometryAt || 0
  },

  fetchFarmApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.fetchFarmApi
  },

  fetchParcelHabitatsApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.fetchParcelHabitatsApi
  },

  isLoading(state, getters) {
    return getters.fetchFarmApi?.isLoading || false
  },

  getCurrentLocationParcelAndZone(state, getters, rootState, rootGetters) {
    let locationParcel = {
      parcelId: null,
      zone: null
    }

    // Do we have a recent current location?
    if (!rootGetters['geo/isLocationTrackingActive']) {
      return locationParcel
    }

    // Find the parcel that the current location is in
    const currentLocation = rootGetters['geo/currentLocation']

    const currentLocationZone = getters.zones.find(zone => {
      let isLocationInPolygon = false

      try {
        isLocationInPolygon = booleanPointInPolygon(point(currentLocation.lngLat), zone.zoneFeature)
      } catch (error) {
        // Sub - bad polygon
      }

      return isLocationInPolygon
    })

    if (currentLocationZone) {
      locationParcel = {
        parcelId: currentLocationZone.parcelId,
        zone: currentLocationZone.zone
      }
    }

    return locationParcel
  },

  getParcelAndZoneForLocation: (state, getters) => lngLat => {
    let locationParcel = {
      parcelId: null,
      zone: null
    }

    const locationZone = getters.zones.find(zone => {
      return booleanPointInPolygon(point(lngLat), zone.zoneFeature)
    })

    if (locationZone) {
      locationParcel = {
        parcelId: locationZone.parcelId,
        zone: locationZone.zone
      }
    }

    return locationParcel
  },

  sampleLocations(state, getters) {
    return (getters?.farm?.locations || [])
      .filter(location => location.type === locationTypes.PLANNED_SAMPLE_LOCATION)
      .sort((a, b) => (date(a.recordedAt) < date(b.recordedAt) ? -1 : 1))
  },

  checkInOutEvents(state, getters) {
    if (!getters?.farm?.locations || getters?.farm?.locations.length === 0) {
      return []
    }

    return getters?.farm?.locations
      .filter(location => [locationTypes.FARM_CHECK_IN, locationTypes.FARM_CHECK_OUT].includes(location.type))
      .sort((a, b) => (date(a.recordedAt) > date(b.recordedAt) ? -1 : 1))
  },

  hasCheckedIn(state, getters, rootState, rootGetters) {
    const app = rootGetters['app/getApp']

    return getters.checkInOutEvents.some(
      location => location.type === locationTypes.FARM_CHECK_IN && location.createdByUser.id === app?.user?.id
    )
  },

  hasCheckedOut(state, getters, rootState, rootGetters) {
    const app = rootGetters['app/getApp']

    return getters.checkInOutEvents.some(
      location => location.type === locationTypes.FARM_CHECK_OUT && location.createdByUser.id === app?.user?.id
    )
  },

  internalNotes(state, getters) {
    return getters.farm?.internalNotes || []
  },

  contextualDataContainerApi(state, getters) {
    return state.organisations[getters.currentOrganisationId]?.contextualDataContainerApi
  },

  contextualDataContainer(state, getters) {
    // Using an unused getter variable here to trigger reactivity when storeParcels isn't part of the state
    /* eslint-disable-next-line no-unused-vars */
    const farmLastUpdated = getters.farmLastUpdatedGeometryAt

    return state.organisations?.[getters.currentOrganisationId]?.contextualDataContainer || undefined
  }
}

export const actions = {
  async getFarm({ state, commit, getters, dispatch, rootGetters }) {
    // TODO: We need to ideally wait for any existing getFarm calls to finish first
    // if (getters.fetchFarmApi?.isLoading) {
    //   console.log('Already fetching farm')
    //   return false
    // }

    console.log('Fetching farm')

    commit('insertOrganisationIfRequired', getters.currentOrganisationId)

    // Load farm from offline storage if available
    if (isNative) {
      const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

      try {
        const file = await Filesystem.readFile({
          path: `farm-${getters.currentOrganisationId}.json`,
          directory: Directory.Data,
          encoding: Encoding.UTF8
        })

        const farm = JSON.parse(file.data)

        console.log('Load local farm', farm)

        storeZones = cloneDeep(farm.zones)
        storeParcels = cloneDeep(farm.parcels)

        delete farm.zones
        delete farm.parcels

        commit('setFarm', { farm, organisationId: getters.currentOrganisationId })
      } catch (error) {
        this.$log.debug('Could not load farm from offline storage', error)
      }
    }

    if (!rootGetters['device/isOnline']) {
      return
    }

    // Using a local variable here to stop reactivity on large response blocking UI
    const fetchFarmApi = new ApiModel()

    commit('setIsLoadingFarmApi', { isLoading: true, organisationId: getters.currentOrganisationId })

    let { response } = await this.$api.farm(fetchFarmApi).getFarm(getters.farmLastUpdatedGeometryAt, getters.farm)

    if (response.data?.parcels) {
      const { organisationId } = response.data

      storeZones = cloneDeep(response.data.zones)
      storeParcels = cloneDeep(response.data.parcels)

      delete response.data.zones
      delete response.data.parcels

      commit('setFarm', { farm: { ...response.data }, organisationId })
    }

    if ('summaryNote' in response.data) {
      commit(
        'report-v3/setSummaryNote',
        { summaryNote: response.data.summaryNote, organisationId: response.data.organisationId },
        { root: true }
      )
    }

    if (isNative) {
      dispatch('saveOfflineParcelsAndZones', response.data)
      dispatch('saveOfflineMap', response.data)
    }

    // Free up memory
    // Release api model memory of large response size
    response = {}
    fetchFarmApi.response.data = []

    commit('setIsLoadingFarmApi', { isLoading: false, organisationId: getters.currentOrganisationId })
  },

  async getParcelHabitats({ commit, getters }, farmId) {
    try {
      await this.$api
        .farm(getters.fetchParcelHabitatsApi)
        .useStorePath(`farm.organisations.${getters.currentOrganisationId}.fetchParcelHabitatsApi`)
        .getParcelHabitats(farmId)

      if (Array.isArray(getters.fetchParcelHabitatsApi.response.data)) {
        commit('saveParcelHabitats', {
          organisationId: getters.currentOrganisationId,
          parcelHabitats: getters.fetchParcelHabitatsApi.response.data
        })
      }
    } catch (error) {
      this.$log.debug('Error fetching parcel habitats', error)
    }
  },

  async saveOfflineParcelsAndZones({ state, commit, getters }, farm) {
    if (!isNative) {
      return false
    }

    const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

    await Filesystem.writeFile({
      path: `farm-${farm.organisationId}.json`,
      data: safeJsonStringify(getters.farm),
      directory: Directory.Data,
      encoding: Encoding.UTF8
    })

    commit('emptyApiData', getters.currentOrganisationId)
  },

  resetZonesAndParcels() {
    storeParcels = []
    storeZones = []
  },

  async saveOfflineMap({ getters, state }, farm) {
    if (!isNative) {
      return false
    }

    if (!farm?.offlineSatelliteImageTiles?.tiles || farm?.offlineSatelliteImageTiles?.tiles.length === 0) {
      this.$log.warn('Farm has not completed zonation yet, aborting offline map saving')

      return false
    }

    this.$log.debug('Downloading offline map images')

    // Save offline satellite map images offline
    const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

    const base64FromUrl = async imageUrl => {
      const response = await fetch(imageUrl)
      const blob = await response.blob()

      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onerror = reject
        reader.onload = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result)
          } else {
            reject(new Error('method did not return a string'))
          }
        }
        reader.readAsDataURL(blob)
      })
    }

    const offlineTiles = farm.offlineSatelliteImageTiles.tiles

    // Loop through all image tiles and download them
    await Promise.all(
      offlineTiles.map(async tile => {
        const base64ImageData = await base64FromUrl(tile.url)

        const fileName = `satellite-image-${farm.id}-${tile.id}.txt`

        this.$log.debug('Saving offline map image', fileName)

        await Filesystem.writeFile({
          path: fileName,
          data: base64ImageData,
          directory: Directory.Data,
          encoding: Encoding.UTF8
        })
      })
    )
  },

  forceHideFarmLoader({ commit, getters }) {
    commit('setForceHiddenLoader', getters.currentOrganisationId)
  },

  async getContextualDataProximityGraph({ commit, state, getters }, farmId) {
    const contextualDataContainer = state.organisations?.[getters.currentOrganisationId]?.contextualDataContainer

    if (contextualDataContainer) {
      return
    }

    const { response } = await this.$api
      .farm(getters.contextualDataContainerApi)
      .useStorePath(`farm.organisations.${getters.currentOrganisationId}.contextualDataContainerApi`)
      .getContextualDataProximityGraphForFarm(farmId)

    if (response.data?.contextualDataProximityGraph) {
      // Edges have their relationship information encoded to save space when serialised, so use the decoder when de-serialising the graph data
      const contextualDataProximityGraph = buildGraphFromJson(
        response.data.contextualDataProximityGraph,
        new EdgeProximityDataEncoder()
      )

      const contextualDataContainer = new ContextualDataContainer(
        contextualDataProximityGraph,
        new ContextualDataDefinitionContainer(response.data.analysisCategories, response.data.layerGroupHeaders)
      )

      commit('setContextualDataContainer', {
        organisationId: getters.currentOrganisationId,
        data: contextualDataContainer
      })
    }
  }
}

export const mutations = {
  setFarm(state, { farm, organisationId }) {
    set(state.organisations[organisationId], 'farm', farm)

    set(state.organisations[organisationId], 'farmLastUpdatedGeometryAt', this.$date().utc().toISOString())
  },

  reset(state) {
    this.$log.debug('Resetting farm module')

    storeParcels = []
    storeZones = []

    Object.assign(state, initialState())
  },

  setForceHiddenLoader(state, organisationId) {
    if (organisationId && state.organisations[organisationId]) {
      set(state.organisations[organisationId], 'hasForceHiddenLoader', true)
    }
  },

  insertOrganisationIfRequired(state, organisationId) {
    if (organisationId && !state.organisations[organisationId]) {
      set(state.organisations, organisationId, createInitialStateForOrganisation())
    }
  },

  saveParcelHabitats(state, { organisationId, parcelHabitats }) {
    set(state.organisations[organisationId], 'parcelHabitats', parcelHabitats)
  },

  setIsLoadingFarmApi(state, { isLoading, organisationId }) {
    if (!organisationId) {
      return false
    }

    set(state.organisations[organisationId].fetchFarmApi, 'isLoading', isLoading)
  },

  emptyApiData(state, organisationId) {
    if (!(organisationId in state.organisations)) {
      return false
    }

    console.log('Emptying farm api response data')

    // set(state.organisations[organisationId].fetchFarmApi.response, 'data', [])

    // Run through all other organisations and delete parcel and zone keys
    Object.keys(state.organisations).forEach(stateOrganisationId => {
      if (stateOrganisationId !== organisationId && state.organisations[stateOrganisationId].farm) {
        set(state.organisations[stateOrganisationId].farm, 'parcels', [])
        set(state.organisations[stateOrganisationId].farm, 'zones', [])
      }
    })
  },

  setContextualDataContainer(state, { organisationId, data }) {
    set(state.organisations[organisationId], 'contextualDataContainer', data)
  }
}
