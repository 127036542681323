import ApiCore from '~/plugins/api/core'
import {
  AuthService,
  ContentService,
  DataEntryService,
  FarmService,
  FileService,
  FoodProductionService,
  FootpathService,
  FundingService,
  GeoService,
  LabService,
  NoteService,
  NotificationService,
  OrganisationService,
  PartnerService,
  RecommendationService,
  ReportingService,
  SamplingService,
  SoilCoverService,
  SurveyService,
  SystemService,
  UserService,
  WaterResultService,
  ZonationService
} from '~/services'
import { ExchangeApi } from '~/services/api/exchange'

export default function (nuxtContext, inject) {
  const api = {
    raw: apiModel => new ApiCore(nuxtContext, apiModel),
    default: apiModel => new ExchangeApi(nuxtContext, apiModel),
    auth: apiModel => new AuthService(nuxtContext, apiModel),
    system: apiModel => new SystemService(nuxtContext, apiModel),
    user: apiModel => new UserService(nuxtContext, apiModel),
    organisation: apiModel => new OrganisationService(nuxtContext, apiModel),
    reporting: apiModel => new ReportingService(nuxtContext, apiModel),
    notification: apiModel => new NotificationService(nuxtContext, apiModel),
    file: apiModel => new FileService(nuxtContext, apiModel),
    geo: apiModel => new GeoService(nuxtContext, apiModel),
    content: apiModel => new ContentService(nuxtContext, apiModel),
    partner: apiModel => new PartnerService(nuxtContext, apiModel),
    zonation: apiModel => new ZonationService(nuxtContext, apiModel, { timeout: 1200000 }),
    survey: apiModel => new SurveyService(nuxtContext, apiModel),
    dataEntry: apiModel => new DataEntryService(nuxtContext, apiModel),
    recommendation: apiModel => new RecommendationService(nuxtContext, apiModel),
    note: apiModel => new NoteService(nuxtContext, apiModel),
    sampling: apiModel => new SamplingService(nuxtContext, apiModel),
    soilCover: apiModel => new SoilCoverService(nuxtContext, apiModel),
    farm: apiModel => new FarmService(nuxtContext, apiModel),
    funding: apiModel => new FundingService(nuxtContext, apiModel),
    waterResult: apiModel => new WaterResultService(nuxtContext, apiModel),
    footpath: apiModel => new FootpathService(nuxtContext, apiModel),
    foodProduction: apiModel => new FoodProductionService(nuxtContext, apiModel),
    lab: apiModel => new LabService(nuxtContext, apiModel)
  }

  // Inject to context as $api
  inject('api', api)
}
